import React, {useEffect, useState} from 'react';
import {Container, Grid, Card, CardMedia, CardContent, Typography, Button, Box} from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ElvenImg from '../assets/images/UI_Chatbot.webp';
import NFTImg from '../assets/images/nftx.png';
import ChainBrainImg from '../assets/images/chainbrain.png';
import GalleryPDF from '../assets/PDF/Eugene_SE_EPK.pdf'
import ElvenGif from '../assets/gifs/ElvinAI.gif';
import ChainBrainGif from '../assets/gifs/chaingif.gif';


const projects = [
    {
        imageUrl: ElvenImg,
        gifUrl: ElvenGif,
        title:'ElvenAI',
        description:"Explore Your Spiritual Journey with Elven AI. We harness the knowledge of tarot, astrology, and mindfulness to provide users with thoughtful and personalized experiences. Our chatbot is designed to be a companion on your journey towards inner peace, enlightenment, and self-awareness.",
        link: 'https://ai.elvenenchantments.art/',
    },
    {
        imageUrl: ChainBrainImg,
        gifUrl: ChainBrainGif,
        title:'ChainBrainCrypto',
        description:"ChainBrainCryptoBot is more than just a trading bot; it's a sophisticated tool that adapts to market changes swiftly, offering YOU a Competitive Edge. Whether you're looking to Automate your Trading Process, Reduce Emotional Decision-Making, or simply Save Time, ChainBrainCryptoBot is your Go-To Solution for Cchieving your crypto trading Objectives with Confidence and Ease.",

    },
    {
        imageUrl: NFTImg,
        gifUrl: 'https://media.tenor.com/2nKSTDDekOgAAAAM/coding-kira.gif',
        title:'NFTCollectX Pro',
        description:"Manage Your NFT Project & Users Information Securely and Easily. Our platform is designed for NFT collection owners to collect, manage, and engage with their community through email list.",
    },
    {
      imageUrl: '',
      gifUrl: 'https://media.tenor.com/2nKSTDDekOgAAAAM/coding-kira.gif',
      title:'StakeWise',
      description:"Bet Smarter, Not Harder",
  },
  {
    imageUrl: 'https://github.com/builde7b0b/website-assets/blob/2248b227c56924dfee673e21a1f64f28b407db28/tech_talent_ui.png?raw=true',
    gifUrl: 'https://media.tenor.com/2nKSTDDekOgAAAAM/coding-kira.gif',
    title:'Tech Talent Match',
    description:"Tech Talent Match is an open-source desktop application that helps connect developers with opportunities based on their skill sets. Built using Rust and the Slint UI framework, it provides a simple and efficient interface for talent registration and matching.",
},
{
  imageUrl: 'https://github.com/builde7b0b/website-assets/blob/2248b227c56924dfee673e21a1f64f28b407db28/g2l.png?raw=true',
  gifUrl: 'https://media.tenor.com/2nKSTDDekOgAAAAM/coding-kira.gif',
  title:'G2L Transport',
  description:"G2L Transport is a platform that connects truck drivers with shippers and freight brokers. It provides a simple and efficient interface for truck drivers to find and book freight, and for shippers and freight brokers to find and book truck drivers.",
},
  
    //ad projects
];

const Gallery = () => {

  const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        AOS.init({duration: 1200});
      }, []);
    return (
      <>
      <Box>
      {/* <Typography className="typing-text" variant="h2" gutterBottom sx={{ color: 'black',fontWeight: 'bold', fontFamily: 'CustomFont, Arial'}}>Recent Projects</Typography> */}
      <Grid sx={{
        backgroundColor: 'black',
      }} container spacing={4}>
        
        {projects.map((project, index) => (
          <Grid  item xs={12} sm={6} md={4} key={index}>
            <Box sx={{
              transition: "transform 0.15s ease-in-out",
              '&:hover': {
                transform: "scale(1.05)",
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.5)",
              }
            }}>
            <Card raised sx={{ backgroundColor: '#8906E6',
                  '& .MuiTypography-root': {
                    marginBottom: 2,
                    martinTop: 2,
                    fontWeight: 'bold',
                    letterSpacing: '0.5px',
                    color: 'white',
                    lineHeight: 1.5,
                  },
                  '& .MuiTypography-body2': {
                    marginBottom: 4,
                    marginTop: 2,
                    padding: 2,
                    fontWeight: 'bold',
                    letterSpacing: '0.5px',
                    color: 'white',
                    lineHeight: 1.5,
                  },
                  
                }}  data-aos="zoom-in">
              <CardMedia 
                component="img"
                height="140"
                image={isHovering ? project.gifUrl : project.imageUrl} // Dynamically set the image based on hover state
                alt={project.title}
                onMouseEnter={() => setIsHovering(true)} // Change to GIF on hover
                onMouseLeave={() => setIsHovering(false)} // Revert to static image on hover leave
                sx={{
                  transition: '0.5s', // Smooth transition for the image source change
                }}
              />
              <CardContent sx={{
                  color: 'black',
                  letterSpacing: '0.5px',
                  
                }}>
                <Typography sx={{
                  
                }} gutterBottom variant="h5" component="div">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {project.description}
                </Typography>
              </CardContent>
            </Card>
            </Box>
          </Grid>
          
        ))}
        <Container sx={{
            textAlign:'center',
        }}>
        <Button sx={{
            margin: '32px',
            backgroundColor: '#050DEB',
            color:'white',
            '&:hover': {
              backgroundColor: '#C484F1', // This will be applied on hover
              color: 'white', // Optional: changing text color on hover
               
            },
        }} size="large" data-aos="zoom-in" variant="contained" color="primary" href={GalleryPDF}>
          View More
        </Button>
        </Container>
        
      </Grid>
      </Box>
      </>
    );
  };
  
  export default Gallery;