import React, {useEffect} from 'react';
import { Box, Grid, Typography, Button, Avatar, Divider, IconButton, Tooltip, Stack } from '@mui/material';
import { Web, Code, Storage, ChargingStation, Assessment, WorkOutline } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import headshotimg from '../assets/headshot.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import azureCertImage from '../assets/images/2024_Redesign/azure-fundamentals.png';
import hackerrankLogo from '../assets/images/2024_Redesign/azure-fundamentals.png';
import leetcodeLogo from '../assets/images/2024_Redesign/azure-fundamentals.png';
import upworkLogo from '../assets/images/2024_Redesign/azure-fundamentals.png';

const UserProfileSection = ({scrollToComponent}) => {

  useEffect(() => {
    AOS.init({duration: 1200});
  }, []);

  const certifications = [
    {
      name: 'AWS Certified',
      image: 'https://www.comnetgroup.com/wp-content/uploads/2021/08/AWS-Certified-Cloud-Practitioner-logo.png',
      link: 'https://cp.certmetrics.com/amazon/en/public/verify/credential/ed10afa3f9ad4927a01b51dbb6137ba6'
    },
    {
      name: 'Azure Fundamentals',
      image: azureCertImage,
      link: 'https://learn.microsoft.com/en-us/users/eugenebrown-6132/credentials/a818e0c9af0afe09?ref=https%3A%2F%2Fwww.linkedin.com%2F'
    }
  ];

  const profiles = [
    {
      name: 'HackerRank',
      image: 'https://www.finsmes.com/wp-content/uploads/2022/03/hackerrank.png',
      link: 'YOUR_HACKERRANK_PROFILE'
    },
    {
      name: 'LeetCode',
      image: 'https://miro.medium.com/v2/resize:fit:1400/1*gBkMCGTAdSk4tu17SCa7RQ.png',
      link: 'https://leetcode.com/u/Genebean/'
    },
    {
      name: 'Upwork',
      image: 'https://portorocha.com/static/442269ac56eddaecd3fa3dd752c38870-1f45a87cbef4613d88ca54b03e3cd2e9.jpeg',
      link: 'https://www.upwork.com/freelancers/~01653d13a25d9b76f1'
    }
  ];

  return (
    <Box sx={{ 
      bgcolor: '#C484F1', 
      p: { xs: 2, md: 3 }, 
      borderRadius: 2, 
      boxShadow: 3, 
      my: 4 
    }}>
      <Divider style={{ margin: '20px 0' }} />
      
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Avatar 
            data-aos="fade-up"
            alt="Eugene"
            src={headshotimg}
            sx={{ 
              width: { xs: 150, sm: 200 }, 
              height: { xs: 150, sm: 200 }, 
              mx: 'auto' 
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={8} lg={9}>
          <Stack spacing={2}>
            <Typography 
              data-aos="slide-left" 
              sx={{
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'CustomFont, Arial',
                fontSize: { xs: '1.75rem', md: '2.125rem' }
              }} 
              variant="h4"
            >
              Hi, I'm Eugene
            </Typography>

            <Typography 
              data-aos="slide-left" 
              sx={{
                color: 'white',
                fontSize: { xs: '1rem', md: '1.25rem' }
              }} 
              variant="subtitle1"
            >
              "Your Full-Stack Software Engineer."
            </Typography>

            {/* Skills Section */}
            <Box sx={{ 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', md: 'flex-start' },
              alignItems: 'center',
              my: 2 
            }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Web sx={{ color: 'white' }} />
                <Typography sx={{color: 'white'}} variant="body2">Frontend</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Code sx={{ color: 'white' }} />
                <Typography sx={{color: 'white'}} variant="body2">Backend</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Storage sx={{ color: 'white' }} />
                <Typography sx={{color: 'white'}} variant="body2">Database</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <ChargingStation sx={{ color: 'white' }} />
                <Typography sx={{color: 'white'}} variant="body2">Blockchain & AI</Typography>
              </Stack>
            </Box>

            {/* Certifications Section */}
            <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>Certifications</Typography>
            <Stack 
              direction="row" 
              spacing={2} 
              sx={{ 
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              {certifications.map((cert, index) => (
                <Tooltip key={index} title={cert.name}>
                  <IconButton 
                    component="a" 
                    href={cert.link}
                    target="_blank"
                    sx={{ p: 0 }}
                  >
                    <img 
                      src={cert.image} 
                      alt={cert.name} 
                      style={{ 
                        width: '60px',
                        height: '60px',
                        objectFit: 'contain'
                      }} 
                    />
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>

            {/* Coding Profiles Section */}
            <Typography variant="h6" sx={{ color: 'white', mt: 2 }}>Coding Profiles</Typography>
            <Stack 
              direction="row" 
              spacing={2} 
              sx={{ 
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: { xs: 'center', md: 'flex-start' }
              }}
            >
              {profiles.map((profile, index) => (
                <Tooltip key={index} title={profile.name}>
                  <IconButton 
                    component="a" 
                    href={profile.link}
                    target="_blank"
                    sx={{ p: 0 }}
                  >
                    <img 
                      src={profile.image} 
                      alt={profile.name} 
                      style={{ 
                        width: '40px',
                        height: '40px',
                        objectFit: 'contain'
                      }} 
                    />
                  </IconButton>
                </Tooltip>
              ))}
            </Stack>

            {/* Social Links */}
            <Stack 
              direction="row" 
              spacing={2} 
              sx={{ 
                justifyContent: { xs: 'center', md: 'flex-start' },
                mt: 2 
              }}
            >
              <Button
                data-aos="fade-up"
                href="https://www.linkedin.com/in/eugene-brown-jr-38210b99/"
                target="_blank"
                sx={{ 
                  backgroundColor: '#050DEB',
                  color: 'white',
                  '&:hover': { backgroundColor: '#0309a3' }
                }}
                startIcon={<LinkedInIcon />}
              >
                LinkedIn
              </Button>
              <Button
                data-aos="fade-up"
                href="https://github.com/builde7b0b"
                target="_blank"
                sx={{ 
                  backgroundColor: '#050DEB',
                  color: 'white',
                  '&:hover': { backgroundColor: '#0309a3' }
                }}
                startIcon={<GitHubIcon />}
              >
                GitHub
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      
      <Divider style={{ margin: '20px 0' }} />
    </Box>
  );
};

export default UserProfileSection;
