import React, { useState, useEffect } from 'react';
import {Divider,  Tabs, Tab, Box, Typography, Paper } from '@mui/material';

import image1 from '../assets/images/chainbrain.png'
import chainBrainGif from '../assets/gifs/chaingif.gif';
import ElvenAIGIf from '../assets/gifs/InnovateWith.codes.gif';
import challengesGif  from '../assets/images/2024_Redesign/case-studies/Infini-CS.gif';
import solutionsGif from '../assets/images/2024_Redesign/case-studies/solutions.gif';
import nftCollectXImage from '../assets/images/NFTCollectX.png';
import AOS from 'aos';
import 'aos/dist/aos.css';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  useEffect(() => {
    AOS.init({duration: 1200});
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const CaseStudiesComponentX = () => {
  const [mainTabValue, setMainTabValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setSubTabValue(0); // Reset sub tab when main tab changes
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTabValue(newValue);
  };

  // Replace with your case studies data
  const caseStudies = [
    {
      title: "NFT Email Collector App: Revolutionizing Communication in the NFT Space",
      challenges: "Need for Efficient Communication: Addressing the gap in direct communication between NFT collection owners and their holders. \n\nVerification of NFT Ownership: Ensuring accurate verification of NFT ownership to gather legitimate email contacts.\n\nIntegration of Diverse Technologies: Combining Flask/Python, MongoDB, Mailgun, and Etherscan API into a cohesive system. \n\nUser-Friendly Interface: Developing an intuitive user interface for both NFT owners and collection owners..",
      
      solutions: "Robust Backend with Flask and Python: Utilized Python with Flask for a stable and efficient backend development. \n\nMongoDB for Flexible Data Management: Adopted MongoDB to handle diverse data formats and ensure scalability. \n\nMailgun for Reliable Email Communication: Integrated Mailgun API for efficient email verification and communication. Etherscan for Accurate NFT Verification: Implemented Etherscan API to authenticate NFT ownership reliably. \n\nStreamlined User Experience: Designed a straightforward and user-friendly interface, enhancing user engagement and ease of use.",
      outcomes: "Effective Communication Channel: Established a direct and efficient line of communication between NFT collection owners and holders. \n\nHigh User Adoption and Engagement: Witnessed a significant uptick in user adoption, with positive feedback on the app's functionality. \n\nAccurate Collection of Verified Emails: Successfully collected and verified a substantial number of email addresses, aiding in marketing and community building. \n\nContinuous Improvement and Expansion: Post-launch enhancements based on user feedback, leading to an increasingly robust and versatile application.",
      challengesLink: challengesGif,
      solutionsLink: solutionsGif,
      mockupLink: nftCollectXImage,
    },
    {
        title: "ChainBrain Crypto: Simplifying Cryptocurrency Insights through a User-Friendly Telegram Bot",
        challenges: "The project aimed to create a centralized platform for crypto insights, addressing the lack of easily accessible, aggregated crypto information.",
        solutions: "ChainBrain Crypto was conceptualized as a Telegram bot, leveraging Python/Flask for backend flexibility and MongoDB for data storage, with a focus on rapidly achieving an MVP.",
        outcomes: "The bot significantly simplified user interactions, offering efficient query handling and data management, with robust security and privacy measures. It received positive feedback for providing timely market insights.",
        
        mockupLink: chainBrainGif,
        challengesLink: challengesGif,
        solutionsLink: solutionsGif,

      },
      {
        title: "InfiniMVP: Revolutionizing Web Development Through Subscription Services",
        challenges: "InfiniMVP emerged as a groundbreaking web development agency, pioneering a subscription-based model to transform how companies transition from concept to code. Inspired by the success of Brett Williams' Design Joy, InfiniMVP aimed to replicate a similar model in the software engineering domain. The initiative targeted providing high-impact, scalable web development services to businesses, encapsuating a unique blend of automation, demand-based procing, and a client centric approach.",
        solutions: "Refining subscription offerings to include packages tailored to different business sizes and needs, ensuring continuous value through ongoing support, updates and development.",
        outcomes: "InfiniMVP's innovate approach is rapidly gaining traction, attracting a diverse clientele appreciative of the subscription model's flexibility, transparency, and scalability. We have successfully challenged traditional web development service models, proving the viability of a subscription-based approach in this domain.",
        
        mockupLink: "",
        challengesLink: challengesGif,
        solutionsLink: solutionsGif,

      },
    // Add more case studies
    {
      title: "ElvenAI",
      challenges: "",
      solutions: "",
      outcomes: "",
      
      mockupLink: ElvenAIGIf,
      challengesLink: challengesGif,
      solutionsLink: solutionsGif,

    },
  ];

  const RenderWithLineBreaks = ({ text }) => {
    return (
      <>
        {text.split('\n\n').map((line, index) => (
          <p key={index} style={{ marginBottom: '1rem' }}>{line}</p>
        ))}
      </>
    );
  };
  

  return (
    <Paper elevation={3} sx={{ 
      margin: { xs: '1rem', md: '2rem' }, 
      padding: { xs: '0.5rem', md: '1rem' } 
    }}>
      <Typography
        data-aos="fade-up"
        variant="h4"
        gutterBottom
        align="center"
        sx={{ 
          fontWeight: 'bold', 
          fontFamily: 'CustomFont, Arial',
          fontSize: { xs: '1.5rem', md: '2.125rem' }
        }}>
        Case Studies: Breakdown
      </Typography>

      <Divider style={{ margin: '20px 0' }} />
      
      <Tabs 
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={mainTabValue} 
        onChange={handleMainTabChange}
        sx={{
          '.MuiTab-root': {
            fontSize: { xs: '0.8rem', md: '1rem' },
            minHeight: { xs: '48px', md: '64px' },
            padding: { xs: '6px 12px', md: '12px 16px' }
          }
        }}
      >
        {caseStudies.map((study, index) => (
          <Tab label={study.title} key={index} />
        ))}
      </Tabs>

      {caseStudies.map((study, index) => (
        <TabPanel 
          data-aos="fade-up"
          value={mainTabValue} 
          index={index} 
          key={index}
          sx={{ 
            padding: { xs: '1rem', md: '2rem' } 
          }}
        >
          <Tabs
            value={subTabValue}
            onChange={handleSubTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            textColor="secondary"
            indicatorColor="secondary"
            sx={{
              '.MuiTab-root': {
                fontSize: { xs: '0.8rem', md: '1rem' },
                minHeight: { xs: '48px', md: '64px' }
              }
            }}
          >
            <Tab label="Challenges" />
            <Tab label="Solutions" />
            <Tab label="Outcomes" />
          </Tabs>

          <TabPanel value={subTabValue} index={0}>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold', 
                color: 'black',
                fontSize: { xs: '1.2rem', md: '1.5rem' }
              }}
            >
              Challenges
            </Typography>

            <RenderWithLineBreaks text={study.challenges} />
            <Box sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 2, md: 4 }
            }}>
              <img 
                data-aos="fade-up"
                src={study.challengesLink} 
                alt="Description"
                style={{ 
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }} 
              />
            </Box>
          </TabPanel>

          <TabPanel value={subTabValue} index={1}>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold', 
                color: 'black',
                fontSize: { xs: '1.2rem', md: '1.5rem' }
              }}
            >
              Solutions
            </Typography>

            <RenderWithLineBreaks text={study.solutions} />
            <Box sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 2, md: 4 }
            }}>
              <img 
                data-aos="fade-up"
                src={study.solutionsLink} 
                alt="Description"
                style={{ 
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }} 
              />
            </Box>
          </TabPanel>

          <TabPanel value={subTabValue} index={2}>
            <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ 
                fontWeight: 'bold', 
                color: 'black',
                fontSize: { xs: '1.2rem', md: '1.5rem' }
              }}
            >
              Outcomes
            </Typography>

            <RenderWithLineBreaks text={study.outcomes} />
            <Box sx={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: 2, md: 4 }
            }}>
              <img 
                data-aos="fade-up"
                src={study.mockupLink} 
                alt="Description"
                style={{ 
                  maxWidth: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }} 
              />
            </Box>
          </TabPanel>
        </TabPanel>
      ))}
    </Paper>
  );
};

export default CaseStudiesComponentX;
