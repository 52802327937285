import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Typography, Grid} from '@mui/material';
import {styled} from '@mui/system';

const FooterContainer = styled(Box)({
    backgroundColor: '#100D28',
    padding: '40px 0',
})

const FooterLink = styled(Link)({
    color: '#050DEB',
    textDecoration: 'none',
    '&:hover':{
        color: 'white',
    },
})

const FooterText = styled(Typography)({
    color: 'white',
    marginBottom: '20px',

});

const FooterBentoBox = styled(Box)({
    backgroundColor: '#C484F1',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  });

const NewsletterBox = styled(Box)({
  backgroundColor: '#C484F1',
  padding: '20px',
  borderRadius: '8px',
  marginBottom: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Footer = () => {
    return (
        <FooterContainer>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Support</FooterText>
                <FooterLink to="/customer-support">Customer Support</FooterLink>

              </FooterBentoBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Contact Us</FooterText>
                <Typography variant="body2" color="white">
                  Email: eugene@infinimvp.com<br />
                  {/* Phone: +1 123-456-7890 */}
                </Typography>
              </FooterBentoBox>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterBentoBox>
                <FooterText variant="h6">Quick Links</FooterText>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink><br />
                <FooterLink to="/terms-and-conditions">Terms of Service</FooterLink>
                
              </FooterBentoBox>
            </Grid>
            <Grid item xs={12}>
              <NewsletterBox>
                <Box sx={{ 
                  width: '100%',
                  maxWidth: '480px',
                  margin: '0 auto',
                  '@media (max-width: 480px)': {
                    width: '100%',
                    height: 'auto'
                  }
                }}>
                  <Typography variant="h6" color="white" gutterBottom align="center">
                    Subscribe to Our Newsletter
                  </Typography>
                  <iframe 
                    src="https://algorithmicinnovations.substack.com/embed" 
                    width="100%" 
                    height="320" 
                    style={{ 
                      border: '1px solid #EEE',
                      background: 'white',
                      borderRadius: '4px',
                      maxWidth: '480px'
                    }} 
                    frameBorder="0" 
                    scrolling="no"
                    title="Newsletter Subscription"
                  />
                </Box>
              </NewsletterBox>
            </Grid>
          </Grid>
          <Typography variant="body2" color="#8906E6" align="center" mt={4}>
            &copy; {new Date().getFullYear()} InifiniMVP. All rights reserved.
          </Typography>
        </FooterContainer>
      );


};

export default Footer;
