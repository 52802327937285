import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)({
  padding: '2rem',
  margin: '2rem 0',
  backgroundColor: '#f8f9fa',
});

const QuestionText = styled(Typography)({
  color: '#100D28',
  fontWeight: 'bold',
  marginTop: '2rem',
  marginBottom: '1rem',
});

const AnswerText = styled(Typography)({
  color: '#333',
  marginBottom: '1.5rem',
  lineHeight: 1.6,
});

const DayInLifeQA = () => {
  return (
    <Container maxWidth="lg">
      <Box py={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          A Day in the Life: Q&A with a Senior Cloud Solutions Architect at InfiniMVP
        </Typography>
        
        <Typography variant="body1" paragraph>
          Meet Eugene Brown Jr, a Senior Cloud Solutions Architect at InfiniMVP, specializing in rapid prototyping 
          and subscription-based app development. With certifications in Azure and AWS, Eugene Brown Jr has built 
          a career designing scalable cloud solutions. After recently transitioning from CVS Health, they are 
          navigating the tech job market while balancing a full plate of projects—and managing valley fever 
          along the way. Let's dive into a typical day in their life.
        </Typography>

        <StyledPaper elevation={2}>
          <QuestionText variant="h6">
            Q: What does your typical day look like?
          </QuestionText>
          <AnswerText variant="body1">
            My day is a carefully balanced mix of hands-on cloud architecture, rapid app development, and 
            keeping my options open in the tech job market. Mornings usually start early with a quick health 
            check-in (valley fever symptoms can be unpredictable). After that, I dive into AWS and Azure 
            environments to refine app prototypes for clients subscribing to our agency's services. Since I 
            work in rapid prototyping, efficiency is key—most apps go from idea to MVP in weeks, not months.
            {'\n\n'}
            Around midday, I step outside for some Scottsdale sun (or at least a breath of fresh air) before 
            tackling my afternoon, which often includes job market research, networking, and keeping up with 
            the latest trends in cloud tech. The evening? Usually a mix of winding down, studying new AWS and 
            Azure features, and preparing for the next day.
          </AnswerText>

          <QuestionText variant="h6">
            Q: How do you balance working at InfiniMVP with looking for new opportunities?
          </QuestionText>
          <AnswerText variant="body1">
            The tech job market is dynamic, and keeping options open is crucial. I dedicate time every week 
            to updating my portfolio, networking on LinkedIn, and engaging with the AWS/Azure community. Since 
            cloud roles are highly in demand, I try to stay ahead by continuously learning, refining my skills, 
            and keeping my GitHub active with personal and agency projects.
            {'\n\n'}
            My experience at CVS Health gave me exposure to enterprise-scale cloud solutions, and now, at 
            InfiniMVP, I get to build lean, fast-moving applications. This contrast makes me a stronger 
            candidate in the job market.
          </AnswerText>

          <QuestionText variant="h6">
            Q: What are some of the most exciting projects you've worked on recently?
          </QuestionText>
          <AnswerText variant="body1">
            One of my favorite projects was a subscription-based SaaS platform that helps small businesses 
            automate their backend processes. I built the entire cloud infrastructure from scratch using AWS 
            Lambda, DynamoDB, and API Gateway—a fully serverless approach that keeps costs low and performance high.
            {'\n\n'}
            Another interesting project was an AI-driven chatbot prototype that leverages Azure Cognitive 
            Services. The challenge was integrating it with existing enterprise APIs while keeping it 
            lightweight enough for an MVP launch.
          </AnswerText>

          <QuestionText variant="h6">
            Q: How does valley fever impact your work routine?
          </QuestionText>
          <AnswerText variant="body1">
            Living with valley fever means I have to be very intentional about my energy management. Some 
            days, the fatigue is intense, so I schedule my most demanding tasks in the morning when I have 
            the most focus. Remote work is a lifesaver—I can take breaks when needed without sacrificing 
            productivity. I also prioritize automation in my work; setting up CI/CD pipelines and serverless 
            functions helps me keep things running smoothly even if I need extra rest.
          </AnswerText>

          <QuestionText variant="h6">
            Q: What tools and technologies do you rely on the most?
          </QuestionText>
          <AnswerText variant="body1">
            My tech stack is a blend of AWS and Azure, depending on project needs:
            {'\n\n'}
            • AWS Lambda & DynamoDB – For building lightweight, scalable serverless applications.
            {'\n'}
            • Azure Functions & Cosmos DB – When working with enterprise clients who prefer the Azure ecosystem.
            {'\n'}
            • Terraform & CloudFormation – Infrastructure as Code (IaC) is a must.
            {'\n'}
            • Docker & Kubernetes – Especially for containerized deployments.
            {'\n'}
            • GitHub Actions & Azure DevOps – Automating CI/CD workflows.
            {'\n'}
            • Postman & GraphQL – For testing APIs efficiently.
          </AnswerText>

          <QuestionText variant="h6">
            Q: What advice would you give to engineers interested in rapid prototyping?
          </QuestionText>
          <AnswerText variant="body1">
            1. Focus on speed, but not at the expense of scalability. Rapid prototyping means building fast 
            MVPs, but a well-architected solution should also be scalable if it gains traction.
            {'\n\n'}
            2. Master serverless and low-code solutions. Many early-stage projects don't need a full 
            Kubernetes cluster—sometimes AWS Amplify or Firebase is the best choice.
            {'\n\n'}
            3. Automate everything. CI/CD pipelines, IaC, monitoring—anything that saves time in deployment 
            is worth learning.
            {'\n\n'}
            4. Stay flexible. Prototyping means pivoting quickly based on client feedback. If you get too 
            attached to one tech stack or one approach, you'll slow yourself down.
          </AnswerText>

          <QuestionText variant="h6">
            Q: What's next for you?
          </QuestionText>
          <AnswerText variant="body1">
            I'm continuing to grow my expertise in multi-cloud solutions, particularly hybrid architectures 
            that blend AWS and Azure. I'm also keeping an eye on AI and automation trends in cloud computing, 
            as those are becoming increasingly relevant for both enterprise and startup clients.
            {'\n\n'}
            On the personal side, managing valley fever while keeping up with a fast-paced career is an 
            ongoing journey, but I've learned how to balance ambition with self-care.
          </AnswerText>
        </StyledPaper>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Final Thoughts
          </Typography>
          <Typography variant="body1" paragraph>
            Cloud engineering is always evolving, and no two days look the same. Whether I'm architecting 
            a new MVP, optimizing an existing cloud solution, or preparing for my next big career move, 
            I embrace the challenge.
          </Typography>
          <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
            🚀 Are you a fellow cloud architect or engineer? Let's connect! Share your thoughts in the comments.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default DayInLifeQA; 